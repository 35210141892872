import { default as _91slug_93U72ZlzW6K8Meta } from "/builds/rezonova/3ifafr-website/front/pages/[[sub]]/[slug].vue?macro=true";
import { default as agendazqjxg0Pr7TMeta } from "/builds/rezonova/3ifafr-website/front/pages/agenda.vue?macro=true";
import { default as _91slug_93ZUq2ZhllzdMeta } from "/builds/rezonova/3ifafr-website/front/pages/article/[slug].vue?macro=true";
import { default as articlesO2Ke49OfCiMeta } from "/builds/rezonova/3ifafr-website/front/pages/articles.vue?macro=true";
import { default as contactlFBzkvQ6gLMeta } from "/builds/rezonova/3ifafr-website/front/pages/contact.vue?macro=true";
import { default as _91slug_93ofju2iQ9PQMeta } from "/builds/rezonova/3ifafr-website/front/pages/evenement/[slug].vue?macro=true";
import { default as evenementssx0uLAo3R9Meta } from "/builds/rezonova/3ifafr-website/front/pages/evenements.vue?macro=true";
import { default as _91slug_93kJb9DzNWshMeta } from "/builds/rezonova/3ifafr-website/front/pages/formation/[slug].vue?macro=true";
import { default as index1oGSYVb2yYMeta } from "/builds/rezonova/3ifafr-website/front/pages/index.vue?macro=true";
import { default as login23xBjGnrnrMeta } from "/builds/rezonova/3ifafr-website/front/pages/login.vue?macro=true";
import { default as _91slug_93uvd5EVIloOMeta } from "/builds/rezonova/3ifafr-website/front/pages/menu-restaurant/[slug].vue?macro=true";
import { default as menus_45restaurantpa9KUcpJfyMeta } from "/builds/rezonova/3ifafr-website/front/pages/menus-restaurant.vue?macro=true";
import { default as mes_45offres_45emploiE3OdGmPyxLMeta } from "/builds/rezonova/3ifafr-website/front/pages/mes-offres-emploi.vue?macro=true";
import { default as _91slug_93aAYJSvWzB6Meta } from "/builds/rezonova/3ifafr-website/front/pages/metier/[slug].vue?macro=true";
import { default as _91slug_93KJVZM9puo2Meta } from "/builds/rezonova/3ifafr-website/front/pages/nos-formations/[slug].vue?macro=true";
import { default as _91slug_934CjXF2nUFtMeta } from "/builds/rezonova/3ifafr-website/front/pages/offres-emploi/[slug].vue?macro=true";
import { default as deposerwgTboeVV1RMeta } from "/builds/rezonova/3ifafr-website/front/pages/offres-emploi/deposer.vue?macro=true";
import { default as indexLbqoEQvMN1Meta } from "/builds/rezonova/3ifafr-website/front/pages/offres-emploi/index.vue?macro=true";
import { default as _91id_93iuH6IlZBoyMeta } from "/builds/rezonova/3ifafr-website/front/pages/offres-emploi/modifier/[id].vue?macro=true";
import { default as index9NfW9W2TH0Meta } from "/builds/rezonova/3ifafr-website/front/pages/profile/index.vue?macro=true";
import { default as modifier47BEUice7mMeta } from "/builds/rezonova/3ifafr-website/front/pages/profile/modifier.vue?macro=true";
export default [
  {
    name: "sub-slug",
    path: "/:sub?/:slug()",
    component: () => import("/builds/rezonova/3ifafr-website/front/pages/[[sub]]/[slug].vue")
  },
  {
    name: "agenda",
    path: "/agenda",
    component: () => import("/builds/rezonova/3ifafr-website/front/pages/agenda.vue")
  },
  {
    name: "article-slug",
    path: "/article/:slug()",
    component: () => import("/builds/rezonova/3ifafr-website/front/pages/article/[slug].vue")
  },
  {
    name: "articles",
    path: "/articles",
    component: () => import("/builds/rezonova/3ifafr-website/front/pages/articles.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/builds/rezonova/3ifafr-website/front/pages/contact.vue")
  },
  {
    name: "evenement-slug",
    path: "/evenement/:slug()",
    component: () => import("/builds/rezonova/3ifafr-website/front/pages/evenement/[slug].vue")
  },
  {
    name: "evenements",
    path: "/evenements",
    component: () => import("/builds/rezonova/3ifafr-website/front/pages/evenements.vue")
  },
  {
    name: "formation-slug",
    path: "/formation/:slug()",
    component: () => import("/builds/rezonova/3ifafr-website/front/pages/formation/[slug].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/rezonova/3ifafr-website/front/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/builds/rezonova/3ifafr-website/front/pages/login.vue")
  },
  {
    name: "menu-restaurant-slug",
    path: "/menu-restaurant/:slug()",
    component: () => import("/builds/rezonova/3ifafr-website/front/pages/menu-restaurant/[slug].vue")
  },
  {
    name: "menus-restaurant",
    path: "/menus-restaurant",
    component: () => import("/builds/rezonova/3ifafr-website/front/pages/menus-restaurant.vue")
  },
  {
    name: "mes-offres-emploi",
    path: "/mes-offres-emploi",
    meta: mes_45offres_45emploiE3OdGmPyxLMeta || {},
    component: () => import("/builds/rezonova/3ifafr-website/front/pages/mes-offres-emploi.vue")
  },
  {
    name: "metier-slug",
    path: "/metier/:slug()",
    component: () => import("/builds/rezonova/3ifafr-website/front/pages/metier/[slug].vue")
  },
  {
    name: "nos-formations-slug",
    path: "/nos-formations/:slug()",
    component: () => import("/builds/rezonova/3ifafr-website/front/pages/nos-formations/[slug].vue")
  },
  {
    name: "offres-emploi-slug",
    path: "/offres-emploi/:slug()",
    component: () => import("/builds/rezonova/3ifafr-website/front/pages/offres-emploi/[slug].vue")
  },
  {
    name: "offres-emploi-deposer",
    path: "/offres-emploi/deposer",
    component: () => import("/builds/rezonova/3ifafr-website/front/pages/offres-emploi/deposer.vue")
  },
  {
    name: "offres-emploi",
    path: "/offres-emploi",
    component: () => import("/builds/rezonova/3ifafr-website/front/pages/offres-emploi/index.vue")
  },
  {
    name: "offres-emploi-modifier-id",
    path: "/offres-emploi/modifier/:id()",
    component: () => import("/builds/rezonova/3ifafr-website/front/pages/offres-emploi/modifier/[id].vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: index9NfW9W2TH0Meta || {},
    component: () => import("/builds/rezonova/3ifafr-website/front/pages/profile/index.vue")
  },
  {
    name: "profile-modifier",
    path: "/profile/modifier",
    component: () => import("/builds/rezonova/3ifafr-website/front/pages/profile/modifier.vue")
  }
]
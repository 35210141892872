import revive_payload_client_4sVQNw7RlN from "/builds/rezonova/3ifafr-website/front/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/rezonova/3ifafr-website/front/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/rezonova/3ifafr-website/front/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/builds/rezonova/3ifafr-website/front/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/builds/rezonova/3ifafr-website/front/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/rezonova/3ifafr-website/front/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/rezonova/3ifafr-website/front/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/rezonova/3ifafr-website/front/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/rezonova/3ifafr-website/front/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/rezonova/3ifafr-website/front/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_dw2T9lEw4h from "/builds/rezonova/3ifafr-website/front/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_izaWKZ8rEu from "/builds/rezonova/3ifafr-website/front/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Uk9bcXDHeN from "/builds/rezonova/3ifafr-website/front/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import sentry_client_VdOeuw25Mb from "/builds/rezonova/3ifafr-website/front/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/builds/rezonova/3ifafr-website/front/.nuxt/sentry-client-config.mjs";
import vue_awesome_paginate_l1b0wTlGPB from "/builds/rezonova/3ifafr-website/front/plugins/vue-awesome-paginate.js";
import _0_auth_client_bxewP6siSC from "/builds/rezonova/3ifafr-website/front/plugins/0.auth.client.ts";
import crsfToken_client_bn2kqPClp0 from "/builds/rezonova/3ifafr-website/front/plugins/crsfToken.client.ts";
import preview_6oGpsaen2C from "/builds/rezonova/3ifafr-website/front/plugins/preview.ts";
import quill_client_yJPGM7qEq9 from "/builds/rezonova/3ifafr-website/front/plugins/quill.client.ts";
import vue_gtm_client_Cm24Dsc5BN from "/builds/rezonova/3ifafr-website/front/plugins/vue-gtm.client.js";
import defaults_aRl2YLnTcb from "/builds/rezonova/3ifafr-website/front/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  titles_dw2T9lEw4h,
  siteConfig_izaWKZ8rEu,
  inferSeoMetaPlugin_Uk9bcXDHeN,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  vue_awesome_paginate_l1b0wTlGPB,
  _0_auth_client_bxewP6siSC,
  crsfToken_client_bn2kqPClp0,
  preview_6oGpsaen2C,
  quill_client_yJPGM7qEq9,
  vue_gtm_client_Cm24Dsc5BN,
  defaults_aRl2YLnTcb
]